<script>
import zxcvbn from 'zxcvbn'

export default {
  name: 'PasswordMeter',
  props: {
    value: {
      type: String,
      default: null
    }
  },
  computed: {
    score () {
      return this.value ? zxcvbn(this.value).score : 0
    }
  }
}
</script>

<template>
  <div class="password-strength-meter">
    <span
      class="password-strength-meter-block"
      :class="{'is-active': score >= 0}"
    />
    <span
      class="password-strength-meter-block"
      :class="{'is-active': score >= 1}"
    />
    <span
      class="password-strength-meter-block"
      :class="{'is-active': score >= 2}"
    />
    <span
      class="password-strength-meter-block"
      :class="{'is-active': score >= 3}"
    />
    <span
      class="password-strength-meter-block"
      :class="{'is-active': score >= 4}"
    />
    <div
      v-if="score <= 1"
      class="tooltip"
    >
      <h5>{{ $t('global:validation.password.weak.title') }}</h5>
      <p>{{ $t('global:validation.password.weak.message') }}</p>
    </div>
    <div
      v-if="score == 2 || score == 3"
      class="tooltip"
    >
      <h5>{{ $t('global:validation.password.moderated.title') }}</h5>
      <p>{{ $t('global:validation.password.moderated.message') }}</p>
    </div>
    <div
      v-if="score >= 4"
      class="tooltip"
    >
      <h5>{{ $t('global:validation.password.strong.title') }}</h5>
      <p>{{ $t('global:validation.password.strong.message') }}</p>
    </div>
  </div>
</template>

<style src="@/assets/styles/themes/default/tooltip.css"></style>
